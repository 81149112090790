import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query distributionDetailsById($id: MongoID!) {
    cat {
      distribution {
        byId(_id: $id) {
          _id
          category
          _category {
            name
          }
          subcategory
          _subcategory {
            name
          }
          position
          _position {
            _id
            id
            title
            _contract {
              _id
              abbreviation
            }
          }
          date_posted
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type DistributionDetailsById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['distribution']>['byId']>

const useDistributionDetailsById = (id: string): DistributionDetailsById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'string'
  })
  const distribution = data?.cat?.distribution?.byId ?? undefined
  return distribution
}

export { useDistributionDetailsById, document as distributionDetailsByIdDocument }
