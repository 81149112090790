import React from 'react'
import RemoveModal from 'src/components/theme/Models/RemoveModal'
import { ContractInterviewersDocument } from 'src/gql/graphql'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { UseToggleStateValueReturn } from 'src/hooks/useToggleStateValue'
import { useMutateDeleteContractInterviewerById } from 'src/queries/cat/contractInterviewer/useMutateDeleteContractInterviewerById'

interface AddModalProps {
  delete: UseToggleStateValueReturn<string>
  contract: string
}

const AddModal: React.FC<AddModalProps> = (props) => {
  const { contract } = props
  const { value: id, hasState: isOpen, toggle } = props.delete

  const mutateDelete = useMutateDeleteContractInterviewerById()
  const invalidate = useInvalidateQueryByDocument(ContractInterviewersDocument)
  const handleDelete = (): void => {
    mutateDelete.mutate({ id }, {
      onSuccess: () => {
        void invalidate({ filter: { contract } })
        toggle()
      }
    })
  }
  return (
    <RemoveModal
      isOpen={isOpen}
      onRemove={handleDelete}
      onCancel={toggle}
      toggle={toggle}
      error={{
        error: 'There was an issue deleting the interviewer.',
        show: mutateDelete.isError
      }}
    >
      Are you sure you want to delete this interviewer?
    </RemoveModal>
  )
}

export default AddModal
