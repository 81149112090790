import { createColumnHelper } from '@tanstack/react-table'
import { ApplicantStages } from 'src/queries'

type TData = ApplicantStages[0]

const columnHelper = createColumnHelper<TData>()

const columns = [
  columnHelper.accessor('name', {
    header: 'Stage'
  }),
  columnHelper.accessor('count', {
    header: 'Count'
  })
]

export { columns }
