import React from 'react'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { useApplicantSources } from 'src/queries'
import { useDashboardState } from '../useDashboardState'

interface CardBodyProps {
  columns: typeof columns
}

const Body: React.FC<CardBodyProps> = (props) => {
  const { columns } = props
  const { start, end } = useDashboardState()
  const sources = useApplicantSources({ start, end })
  return (
    <Table storageKey='dashboard-applicant-sources' options={{ data: sources, columns, initialState: { sorting: [{ id: 'category', desc: false }] } }} />
  )
}

export default Body
