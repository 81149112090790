import { sub } from 'date-fns'
import { createContext, useContext, useEffect, useState } from 'react'

interface DashboardState {
  start: Date
  end: Date
}

export type SetDate = (date: Date) => void

export interface DashboardContextValue extends DashboardState {
  setStart: SetDate
  setEnd: SetDate
}

const DashboardContext = createContext<DashboardContextValue | undefined>(undefined)

const STORAGE_KEY = 'cat-dashboard-global-state'

const DashboardContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const storage = localStorage.getItem(STORAGE_KEY)
  const defaultState: DashboardState = {
    start: sub(new Date(), { days: 30 }),
    end: new Date()
  }
  const baseState: DashboardState = storage !== null ? JSON.parse(storage) : defaultState
  const [state, setState] = useState<DashboardState>(baseState)

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
  }, [state])

  const context: DashboardContextValue = {
    ...state,
    setStart: (start: Date) => setState((state) => ({ ...state, start })),
    setEnd: (end: Date) => setState((state) => ({ ...state, end }))
  }

  return (
    <DashboardContext.Provider value={context}>
      {props.children}
    </DashboardContext.Provider>
  )
}

const useDashboardState = (): DashboardContextValue => {
  const context = useContext(DashboardContext)
  if (context == null) {
    throw new Error('useDashboardState must be used within a DashboardContextProvider')
  }
  return context
}

export { DashboardContextProvider, useDashboardState }
