import React, { useCallback, useState } from 'react'

type HandleToggle<Value> = (newValue?: Value) => void

export interface UseToggleStateValueReturn<Value> {
  value: Value | undefined
  setValue: React.Dispatch<React.SetStateAction<Value | undefined>>
  toggle: HandleToggle<Value>
  hasState: boolean
}

const useToggleStateValue = <Value = boolean>(defaultStateValue?: Value): UseToggleStateValueReturn<Value> => {
  const [value, setValue] = useState<Value | undefined>(defaultStateValue)
  const handleToggle: HandleToggle<Value> = useCallback(
    (newValue?: Value): void => setValue(newValue),
    [setValue]
  )
  const hasState = value !== undefined

  return {
    value,
    setValue,
    toggle: handleToggle,
    hasState
  }
}

export default useToggleStateValue
