import { Formik } from 'formik'
import React from 'react'
import { contractInterviewersDocument, useMutateCreateContractInterviewer } from 'src/queries'
import Form from './Form'
import validationSchema from '../helpers/formValidation'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'

interface FormContextProps {
  toggle: () => void
  contract: string
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { toggle, contract } = props
  const create = useMutateCreateContractInterviewer()
  const invalidateInterviewers = useInvalidateQueryByDocument(contractInterviewersDocument)
  const handleSuccess = (): void => {
    void invalidateInterviewers({ filter: { contract } })
    toggle()
  }
  return (
    <Formik
      initialValues={{
        name: '',
        title: '',
        email: ''
      }}
      validationSchema={validationSchema}
      onSubmit={async (data) => {
        const record = {
          ...data,
          contract
        }

        create.mutate({ record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form toggle={toggle} isLoading={create.isLoading} hasError={create.isError} />
      )}
    </Formik>
  )
}

export default FormContext
