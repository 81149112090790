import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateDeleteContractInterviewerByIdMutation, MutateDeleteContractInterviewerByIdMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'
// import { contractInterviewerCreatedUpdatedByIdDocument } from '../useContractInterviewerCreatedUpdatedById'

const document = graphql(/* graphql */`
  mutation mutateDeleteContractInterviewerById($id: MongoID!) {
    cat {
      contractInterviewer {
        deleteById(_id: $id) {
          recordId
        }
      }
    }
  }
`)

export type UseMutateDeleteContractInterviewerByIdReturn = UseMutationResult<
MutateDeleteContractInterviewerByIdMutation,
unknown,
MutateDeleteContractInterviewerByIdMutationVariables
>

const useMutateDeleteContractInterviewerById = (): UseMutateDeleteContractInterviewerByIdReturn => {
  return useGraphMutation(document)
}

export { useMutateDeleteContractInterviewerById, document as mutateContractInterviewerByIdDocument }
