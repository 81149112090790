import { parse } from 'date-fns'
import React, { useMemo } from 'react'
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { dates } from 'src/helpers/displayhelpers'

interface DatePickerProps {
  label: string
  name: string
  value: Date
  onChange: (value: Date) => void
  error?: string
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { value, label, name, error, onChange } = props
  const hasError = Boolean(error)

  const parsedValue = useMemo(() => {
    return dates.systemDateOnly(value)
  }, [value])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const val = e.target.value
    const parsed = parse(val, 'yyyy-MM-dd', new Date())
    void onChange(parsed)
  }

  return (
    <div>
      <FormGroup>
        <Label for={name} className='fw-bold fs-6'>
          {label}
        </Label>
        <Input bsSize='sm' invalid={hasError} value={parsedValue} onChange={handleChange} id={name} type='date' />
        <FormFeedback>
          {error}
        </FormFeedback>
      </FormGroup>
    </div>
  )
}

export default DatePicker
