import { graphql } from 'src/gql'
import { AverageTimeToFillQueryVariables } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query averageTimeToFill ($start: Date!, $end: Date!) {
    reporting {
      averageTimeToFill(start: $start, end: $end) {
        avg
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type AverageTimeToFill = NonNullable<NonNullable<NonNullable<TData>['reporting']>['averageTimeToFill']>

const useAverageTimeToFill = (variables: AverageTimeToFillQueryVariables): AverageTimeToFill => {
  const { data } = useGraphQL(document, variables)
  return data?.reporting?.averageTimeToFill ?? { avg: 0 }
}

export { useAverageTimeToFill, document as averageTimeToFillDocument }
