import { graphql } from 'src/gql'
import { ContractInterviewerByIdQueryVariables } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL, UseGraphqlOptions } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query contractInterviewerById($id: MongoID!) {
    cat {
      contractInterviewer {
        byId(_id: $id) {
          _id
          name
          title
          email
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type ContractInterviewerById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['contractInterviewer']>['byId']>

const useContractInterviewerById = (id: string, variables?: ContractInterviewerByIdQueryVariables, options?: UseGraphqlOptions<TData>, enabled: boolean = true): ContractInterviewerById | undefined => {
  const internalVariables: ContractInterviewerByIdQueryVariables = Object.assign({
    id
  }, variables)
  const { data } = useGraphQL(document, internalVariables, options)
  return data?.cat?.contractInterviewer?.byId ?? undefined
}

export { useContractInterviewerById, document as contractInterviewerByIdDocument }
