import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

export interface LinkTemplateProps {
  _id: any
  to: LinkProps['to']
}

const LinkTemplate: React.FC<React.PropsWithChildren<LinkTemplateProps>> = (props) => {
  const { _id, children, to } = props

  if (_id === undefined || _id === null) {
    return <>{children}</>
  }

  return (
    <Link to={to}>
      {children}
    </Link>
  )
}

export default LinkTemplate
