import React from 'react'
import { useCandidateDetailsById } from 'src/queries'

interface BodyProps {
  id: string
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const candidate = useCandidateDetailsById(id)
  const name = [candidate?.name?.last, candidate?.name?.first].filter(v => typeof v === 'string').join(', ')
  return (
    <>
      Are you sure you want to delete <b>{name}</b>?
    </>
  )
}

export default Body
