import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateContractInterviewerByIdMutation, MutateContractInterviewerByIdMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'
// import { contractInterviewerCreatedUpdatedByIdDocument } from '../useContractInterviewerCreatedUpdatedById'

const document = graphql(/* graphql */`
  mutation mutateContractInterviewerById($id: MongoID!, $record: UpdateByIdContractInterviewerInput!) {
    cat {
      contractInterviewer {
        updateById(_id: $id, record: $record) {
          recordId
        }
      }
    }
  }
`)

export type UseMutateContractInterviewerByIdReturn = UseMutationResult<
MutateContractInterviewerByIdMutation,
unknown,
MutateContractInterviewerByIdMutationVariables
>

const useMutateContractInterviewerById = (): UseMutateContractInterviewerByIdReturn => {
  // const invalidateCreatedUpdated = useInvalidateQueryByDocument(contractInterviewerCreatedUpdatedByIdDocument)
  return useGraphMutation(document, {
    onSuccess: (data) => {
      const id = data.cat?.contractInterviewer?.updateById?.recordId
      if (typeof id === 'string') {
        // void invalidateCreatedUpdated({ id })
      }
    }
  })
}

export { useMutateContractInterviewerById, document as mutateContractInterviewerByIdDocument }
