import { Formik } from 'formik'
import React from 'react'
import {
  contractInterviewersDocument,
  useContractInterviewerById,
  UseMutateContractInterviewerByIdReturn,
  useMutateContractInterviewerById,
  contractInterviewerByIdDocument
} from 'src/queries'
import Form from './Form'
import validationSchema from '../helpers/formValidation'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { pick } from 'lodash'

interface FormContextProps {
  toggle: () => void
  contract: string
  id: string
}

export type Values = Parameters<UseMutateContractInterviewerByIdReturn['mutate']>[0]['record']

const FormContext: React.FC<FormContextProps> = (props) => {
  const { toggle, contract, id } = props
  const update = useMutateContractInterviewerById()
  const interviewer = useContractInterviewerById(id)
  const invalidateInterviewers = useInvalidateQueryByDocument(contractInterviewersDocument)
  const invalidateIntervewer = useInvalidateQueryByDocument(contractInterviewerByIdDocument)
  const handleSuccess = (): void => {
    void invalidateInterviewers({ filter: { contract } })
    void invalidateIntervewer({ id })
    toggle()
  }
  const initialState: Values = pick(interviewer, [
    'name',
    'title',
    'email'
  ])
  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={async (data) => {
        const record = {
          ...data,
          contract
        }

        update.mutate({ id, record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form toggle={toggle} isLoading={update.isLoading} hasError={update.isError} />
      )}
    </Formik>
  )
}

export default FormContext
