import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query candidateExhibitById($id: MongoID!) {
    cat {
      candidate {
        byId(_id: $id) {
          _id
          exhibit {
            sent_for_review
            hm_approved
            contracts_approved
            extended
            signed
            pmo_approved
          }
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type CandidateExhibitById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['candidate']>['byId']>

const useCandidateExhibitById = (id: string): CandidateExhibitById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'string'
  })
  const candidate = data?.cat?.candidate?.byId ?? undefined
  return candidate
}

export { useCandidateExhibitById, document as candidateExhibitByIdDocument }
