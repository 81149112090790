import React, { Suspense } from 'react'
import Card from '../../../../../../components/Card'
import Body from './Body'
import List from './List'
import EditModel from './EditModal'
import useToggleState from 'src/hooks/useToggleState'
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import DeleteModel from './DeleteModel'

interface DetailsProps {
  id: string
}

const Details: React.FC<DetailsProps> = (props) => {
  const { id } = props
  const [isOpen, , handleToggle] = useToggleState()
  const [isDeleteOpen, , handleDeleteToggle] = useToggleState()
  return (
    <>
      <Card
        title='Candidate'
        primaryAction={{
          key: 'edit-candidate-details',
          icon: faPencil,
          onClick: handleToggle,
          permissionKey: 'CAT_EDIT'
        }}
        secondaryActions={[{
          key: 'delete-candidate',
          onClick: handleDeleteToggle,
          icon: faTrash,
          text: 'Delete',
          permissionKey: 'CAT_EDIT',
          iconProps: {
            className: 'text-danger'
          }
        }]}
      >
        <Suspense fallback={<List candidate={undefined} />}>
          <Body id={id} />
        </Suspense>
      </Card>
      <EditModel id={id} isOpen={isOpen} toggle={handleToggle} />
      <DeleteModel id={id} isOpen={isDeleteOpen} toggle={handleDeleteToggle} />
    </>
  )
}

export default Details
