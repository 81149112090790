import React from 'react'
import { Col, ColProps, Row } from 'reactstrap'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import { yesno } from 'src/helpers/displayhelpers'
import { PositionDetailsById } from 'src/queries'
import DateDisplay from '../../../../../../components/DateDisplay'
import { ContractLink } from 'src/components/Links'

interface ListProps {
  position: PositionDetailsById | undefined
}

interface StatusDateProps {
  date: Date | undefined
}

const StatusDate: React.FC<StatusDateProps> = (props) => {
  const { date } = props
  if (date === undefined) {
    return null
  }

  return (
    <>
      (<DateDisplay id='statusDate' date={date} />)
    </>
  )
}

const List: React.FC<ListProps> = (props) => {
  const { position } = props
  const statusDateMap: Record<string, Date> = {
    Filled: position?.filled_date,
    Closed: position?.closed_date
  }
  const status = position?._status?.name ?? undefined
  const statusDate = status !== undefined ? statusDateMap[status] : undefined
  const contractType = position?._contract_type?.name ?? undefined
  const section1: Array<Omit<ItemProps, 'id'>> = [
    { title: 'Title', value: position?.title },
    {
      title: 'Contract',
      value: (
        <>
          <ContractLink _id={position?._contract?._id}>
            {position?._contract?.abbreviation}
          </ContractLink>
          {contractType !== undefined ? ` (${contractType})` : null}
        </>
      )
    },
    {
      title: 'Status',
      value: (
        <>{position?._status?.name}{statusDate !== undefined ? ' ' : null}<StatusDate date={statusDate} /></>
      )
    },
    { title: 'Priority', value: position?._priority?.name }
  ]
  const section2 = [
    { title: 'Program Manager', value: position?._program_manager?.name },
    { title: 'Hiring Manager', value: position?._hiring_manager?.name },
    { title: 'Clearance', value: position?._required_clearance?.name },
    { title: 'Location', value: position?.location }
  ]
  const section3 = [
    { title: 'Backfill', value: yesno(position?.backfill) },
    { title: 'Direct Hire', value: yesno(position?.direct_hire) },
    { title: 'Temp to Perm', value: yesno(position?.temp_to_perm) },
    { title: 'Keywords', value: position?.keywords }
  ]
  const dlProps = {
    className: 'row-cols-1',
    suspended: position === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  const colProps: ColProps = {
    md: 4,
    sm: 12
  }
  return (
    <Row>
      <Col {...colProps}>
        <DescriptionList {...dlProps}>
          {section1.map(item => {
            const key = item.title.toLowerCase().replace(' ', '-')
            return <Item {...item} key={key} id={key} />
          })}
        </DescriptionList>
      </Col>
      <Col {...colProps}>
        <DescriptionList {...dlProps}>
          {section2.map(item => {
            const key = item.title.toLowerCase().replace(' ', '-')
            return <Item {...item} key={key} id={key} />
          })}
        </DescriptionList>
      </Col>
      <Col {...colProps}>
        <DescriptionList {...dlProps}>
          {section3.map(item => {
            const key = item.title.toLowerCase().replace(' ', '-')
            return <Item {...item} key={key} id={key} />
          })}
        </DescriptionList>
      </Col>
    </Row>
  )
}

export default List
