import React from 'react'
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import { Input } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
}

const Form: React.FC<FormProps> = (props) => {
  const { hasError, toggle, isLoading, disableSave } = props
  const { handleSubmit, isValid } = useFormikContext()
  const canSave = disableSave === false && isValid && isLoading === false
  return (
    <>
      <Body error={{
        error: 'There was an issue adding the contract.',
        show: hasError ?? false
      }}
      >
        <Input type='text' name='name' label='Name' />
        <Input type='text' name='title' label='Title' />
        <Input type='text' name='email' label='E-Mail' />
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Create',
          icon: isLoading === true ? faSpinner : faPlus,
          color: 'primary',
          disabled: canSave
        }}
      />
    </>
  )
}

export default Form
