import { graphql } from 'src/gql'
import { ApplicantSourcesQueryVariables } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query applicantSources ($start: Date!, $end: Date!) {
    reporting {
      applicantSources(start: $start, end: $end) {
        _id
        source
        count
        category {
          _id
          name
          count
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type ApplicantSources = NonNullable<NonNullable<NonNullable<TData>['reporting']>['applicantSources']>

const useApplicantSources = (variables: ApplicantSourcesQueryVariables): ApplicantSources => {
  const { data } = useGraphQL(document, variables)
  return data?.reporting?.applicantSources ?? []
}

export { useApplicantSources, document as applicantSourcesDocument }
