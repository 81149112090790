import React, { Suspense } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import RemoveModal from 'src/components/theme/Models/RemoveModal'
import { useMutateRemoveCandidateById } from 'src/queries'
import Body from './Body'
import { Col } from 'reactstrap'

interface AddModalProps {
  isOpen: boolean
  toggle: () => void
  id: string
}

const AddModal: React.FC<AddModalProps> = (props) => {
  const { isOpen, toggle, id } = props

  const mutateRemove = useMutateRemoveCandidateById()
  const nav = useNavigate()
  const location = useLocation()
  const handleRemove = (): void => {
    mutateRemove.mutate({ id }, {
      onSuccess: (results) => {
        if (location.key === 'default') {
          nav('/')
        } else {
          nav(-1)
        }
      }
    })
  }
  return (
    <RemoveModal
      isOpen={isOpen}
      onRemove={handleRemove}
      toggle={toggle}
      error={{
        error: 'There was an issue deleting the candidate.',
        show: mutateRemove.isError
      }}
    >
      <Suspense fallback={<Col className='col-12 placeholder' data-testid='placeholder' />}>
        <Body id={id} />
      </Suspense>
    </RemoveModal>
  )
}

export default AddModal
