import { FieldArray, useField } from 'formik'
import React from 'react'
import DateRangeForm from './DateRangeForm'
import { add } from 'date-fns'
import { Button, Col, Row } from 'reactstrap'
import isValid from 'date-fns/isValid'

interface DatesProps {
  fieldName: string
  title: string
}

const Dates: React.FC<DatesProps> = (props) => {
  const [inputProps, meta] = useField(props.fieldName)
  const dates = inputProps.value
  const errors = meta.error
  let prevDate = dates[dates.length - 1]?.end
  prevDate = typeof prevDate === 'string' ? new Date(prevDate) : prevDate
  prevDate = isValid(prevDate) ? prevDate : undefined
  const nextDate = add(prevDate, {
    days: 1
  })
  if (dates === undefined || !Array.isArray(dates)) {
    return null
  }

  return (
    <FieldArray
      name={props.fieldName}
      render={({ push, remove }) => (
        <>
          <div>
            {dates.map((_, index) => (
              <DateRangeForm key={index} name={`${props.fieldName}[${index}]`} label={`${props.title} ${index + 1}`} remove={() => remove(index)} />
            ))}
            {typeof errors === 'string' && (
              <Row className='text-danger'>
                <Col className='mb-3'>
                  {errors}
                </Col>
              </Row>
            )}
            <Button type='button' color='link' onClick={() => push({ start: nextDate })}>
              + Add {props.title}
            </Button>
          </div>
        </>
      )}
    />
  )
}

export default Dates
