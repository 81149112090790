import React from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import RouteError from '../../../../helpers/RouteError'
import Details from './cards/Details'
import PositionDetails from './cards/PositionDetails'
import Status from './cards/Status'
import Rates from './cards/Rates'
import PositionRates from './cards/PositionRates'
import ResumeReview from './cards/ResumeReview'
import Interviews from './cards/Interviews'
import ContractualDocuments from './cards/ContractualDocuments'
import Notes from './cards/Notes'
import CreatedUpdated from './cards/CreatedUpdated'
import Attachments from '../../../../components/Attachments'
import Exhibit from './cards/Exhibit'
import OfferLetter from './cards/OfferLetter'
import { useCandidateDetailsById } from 'src/queries'
import ContactInfo from './cards/ContactInfo'

const Candidate: React.FC = (props) => {
  const { id } = useParams()
  if (id === undefined) {
    throw new RouteError('Candidate was not found.', { status: 404 })
  }
  const candidate = useCandidateDetailsById(id)
  if (candidate === null || candidate === undefined) {
    throw new RouteError('Candidate was not found.', { status: 404 })
  }
  return (
    <>
      <Row className='mb-4 gy-4'>
        <Col md='5'>
          <Details id={id} />
        </Col>
        <Col md='4'>
          <PositionDetails id={id} />
        </Col>
        <Col md={3}>
          <Status id={id} />
        </Col>
      </Row>
      <Row className='gy-4'>
        <Col md='6'>
          <Row className='gy-4'>
            <Col md='12'>
              <ContactInfo id={id} />
            </Col>
            <Col md='6'>
              <Rates id={id} />
            </Col>
            <Col md='6'>
              <PositionRates id={id} />
            </Col>
            <Col md='6'>
              <ResumeReview id={id} />
            </Col>
            <Col md='6'>
              <Interviews id={id} />
            </Col>
            <Col md='6'>
              <ContractualDocuments id={id} />
            </Col>
            {candidate?._type?.name === 'Temp to Perm'
              ? (
                <Col md='6'>
                  <Exhibit id={id} />
                </Col>
                )
              : null}
            {candidate?._type?.name === 'Direct Hire'
              ? (
                <Col md='6'>
                  <OfferLetter id={id} />
                </Col>
                )
              : null}
          </Row>
        </Col>
        <Col>
          <Row className='gy-4'>
            <Col xs='12'>
              <Notes id={id} />
            </Col>
            <Col xs='12'>
              <Attachments link={id} type='6058d4799c5e6a11b0083ac2' model='Candidate' editPermissionKey='CAT_EDIT' />
            </Col>
            <Col xs='12'>
              <CreatedUpdated id={id} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Candidate
