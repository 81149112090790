import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'
import { Button, ButtonProps as BSButtonProps } from 'reactstrap'

interface ButtonProps extends Omit<BSButtonProps, 'className'> {
  className?: classnames.Argument
}

export interface PrimaryActionProps extends ButtonProps {
  key: string
  icon: IconDefinition
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>
  text?: string
  permissionKey?: string
}

const PrimaryAction: React.FC<PrimaryActionProps> = (props) => {
  const { icon, text, tag, iconProps, color, className, permissionKey, ...rest } = props
  const buttonColor = color === undefined ? text !== undefined ? 'light' : 'link' : color
  const buttonClass = classnames(
    'text-nowrap',
    { 'text-reset': buttonColor === 'link' },
    className
  )
  return (
    <Button
      tag={tag}
      size='sm'
      color={buttonColor}
      {...rest}
      className={buttonClass}
      data-testid={'id' in props && `primary-action-${String(props.id)}`}
    >
      <>
        <FontAwesomeIcon icon={icon} data-testid={`icon-${icon.iconName}`} {...iconProps} /> {text}
      </>
    </Button>
  )
}

export default PrimaryAction
