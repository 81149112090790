import React from 'react'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { useApplicantStages } from 'src/queries'
import { useDashboardState } from '../useDashboardState'

const Body: React.FC = (props) => {
  const { start, end } = useDashboardState()
  const data = useApplicantStages({ start, end })
  return (
    <Table storageKey='dashboard-applicant-stages' options={{ data, columns }} />
  )
}

export default Body
