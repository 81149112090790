import { graphql } from 'src/gql'
import { ApplicantStagesQueryVariables } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query applicantStages ($start: Date!, $end: Date!) {
    reporting {
      applicantStages(start: $start, end: $end) {
        name 
        count
      }
    } 
  }
`)

type TData = GetTData<typeof document>
export type ApplicantStages = NonNullable<NonNullable<NonNullable<TData>['reporting']>['applicantStages']>

const useApplicantStages = (variables: ApplicantStagesQueryVariables): ApplicantStages => {
  const { data } = useGraphQL(document, variables)
  return data?.reporting?.applicantStages ?? []
}

export { useApplicantStages, document as applicantStagesDocument }
