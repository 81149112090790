import React, { Suspense } from 'react'
import Card from 'src/components/Card'
import CardBody from './CardBody'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'

const ApplicantSources: React.FC = (props) => {
  return (
    <>
      <Card
        title='Application Sources'
      >
        <Suspense fallback={<Table options={{ data: [], columns }} suspend={3} />}>
          <CardBody columns={columns} />
        </Suspense>
      </Card>
    </>
  )
}

export default ApplicantSources
