import { graphql } from 'src/gql'
import { ContractPopByIdQueryVariables } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL, UseGraphqlOptions } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query contractPopById($id: MongoID!) {
    cat {
      contract {
        byId(_id: $id) {
          _id
          pop {
            status
            base {
              start
              end
            }
            options {
              start
              end
            }
            extensions {
              start
              end
            }
          }
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type ContractPopById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['contract']>['byId']>

const useContractPopById = (id: string, variables?: ContractPopByIdQueryVariables, options?: UseGraphqlOptions<TData>, enabled: boolean = true): ContractPopById | undefined => {
  const internalVariables: ContractPopByIdQueryVariables = Object.assign({
    id
  }, variables)
  const { data } = useGraphQL(document, internalVariables, options)
  return data?.cat?.contract?.byId ?? undefined
}

export { useContractPopById, document as contractPopByIdDocument }
