import { createColumnHelper } from '@tanstack/react-table'
import { ApplicantSources } from 'src/queries'

type TData = ApplicantSources[0]

const columnHelper = createColumnHelper<TData>()

const columns = [
  columnHelper.accessor('source', {
    header: 'Source'
  }),
  columnHelper.accessor('category.name', {
    id: 'category',
    header: 'Category',
    cell: (info) => {
      const value = info.getValue()
      const count = info.row.original.category?.count
      return (
        <>
          {value} {count !== undefined ? ` (${count})` : ''}
        </>
      )
    }
  }),
  columnHelper.accessor('count', {
    header: 'Count'
  })
]

export { columns }
