import React from 'react'
import { Col, Row } from 'reactstrap'
import Card from 'src/components/Card'
import DashboardDates from './DashboardDates'
import { DashboardContextProvider } from './useDashboardState'
import AverageTimeToFill from './AverageTimeToFill'
import ApplicantSources from './ApplicantSources'
import ApplicantStages from 'src/pages/cat/view/dashboard/ApplicantStages'

const Dashboard: React.FC = (props) => {
  return (
    <DashboardContextProvider>
      <Row className='mb-4'>
        <Col md='8'>
          <Card title='Dashboard'>
            <DashboardDates />
          </Card>
        </Col>
        <Col className='d-flex'>
          <AverageTimeToFill />
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col md='8'>
          <ApplicantSources />
        </Col>
        <Col>
          <ApplicantStages />
        </Col>
      </Row>
    </DashboardContextProvider>
  )
}

export default Dashboard
