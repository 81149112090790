import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateCreateContractInterviewerMutation, MutateCreateContractInterviewerMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation mutateCreateContractInterviewer($record: CreateOneContractInterviewerInput!) {
    cat {
      contractInterviewer {
        createOne(record: $record) {
          recordId
        }
      }
    }
  }
`)

export type UseMutateCreateContractInterviewerReturn = UseMutationResult<
MutateCreateContractInterviewerMutation,
unknown,
MutateCreateContractInterviewerMutationVariables
>

const useMutateCreateContractInterviewer = (): UseMutateCreateContractInterviewerReturn => {
  return useGraphMutation(document)
}

export { useMutateCreateContractInterviewer, document as mutateCreateContractInterviewerDocument }
