import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateRemoveCandidateByIdMutation, MutateRemoveCandidateByIdMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation mutateRemoveCandidateById($id: MongoID!) {
    cat {
      candidate {
        removeById(_id: $id) {
          recordId
        }
      }
    }
  }
`)

export type UseMutateRemoveCandidateByIdReturn = UseMutationResult<
MutateRemoveCandidateByIdMutation,
unknown,
MutateRemoveCandidateByIdMutationVariables
>

const useMutateRemoveCandidateById = (): UseMutateRemoveCandidateByIdReturn => {
  return useGraphMutation(document)
}

export { useMutateRemoveCandidateById, document as mutateRemoveCandidateByIdDocument }
