import React from 'react'
import { Col, Row } from 'reactstrap'
import DatePicker from './DatePicker'
import { useDashboardState } from 'src/pages/cat/view/dashboard/useDashboardState'

const DashboardDates: React.FC = () => {
  const state = useDashboardState()

  const handleStartChange = state.setStart
  const handleEndChange = state.setEnd

  return (
    <Row>
      <Col md={6}>
        <DatePicker
          label='Start Date'
          name='startDate'
          value={state.start}
          onChange={handleStartChange}
        />
      </Col>
      <Col md={6}>
        <DatePicker
          label='End Date'
          name='endDate'
          value={state.end}
          onChange={handleEndChange}
        />
      </Col>
    </Row>
  )
}

export default DashboardDates
