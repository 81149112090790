import React, { Suspense } from 'react'
import Card from 'src/components/Card'
import CardBody from './CardBody'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'

const ApplicantStages: React.FC = (props) => {
  return (
    <>
      <Card title='Recruitment Funnel'>
        <Suspense fallback={<Table options={{ data: [], columns }} suspend={3} />}>
          <CardBody />
        </Suspense>
      </Card>
    </>
  )
}

export default ApplicantStages
