import React, { Suspense, useMemo } from 'react'
import Card from 'src/components/Card'
import CardBody from './CardBody'
import { Table } from '@imcva/reactstrap-table'
import { createColumns } from './columns'
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import useToggleState from 'src/hooks/useToggleState'
import AddModal from './AddModal'
import EditModal from 'src/pages/cat/contracts/Contract/cards/Interviewers/EditModel'
import useToggleStateValue from 'src/hooks/useToggleStateValue'
import DeleteModal from './DeleteModel'

interface InterviewersProps {
  contract: string
}

const Interviewers: React.FC<InterviewersProps> = (props) => {
  const { contract } = props
  const [isOpen, , handleToggle] = useToggleState()
  const editModal = useToggleStateValue<string>()
  const deleteModal = useToggleStateValue<string>()

  const columns = useMemo(() => {
    return createColumns([
      {
        id: 'edit',
        title: 'Edit',
        icon: faPencil,
        onClick: editModal.toggle
      },
      {
        id: 'delete',
        title: 'Delete',
        icon: faTrash,
        color: 'danger',
        onClick: deleteModal.toggle
      }
    ])
  }, [editModal.toggle, deleteModal.toggle])

  return (
    <>
      <Card
        title='Interviewers'
        primaryAction={[
          {
            key: 'add-interviewer',
            icon: faPlus,
            text: 'Add Interviewer',
            onClick: handleToggle,
            permissionKey: 'CAT_EDIT'
          }
        ]}
      >
        <Suspense fallback={<Table options={{ data: [], columns }} suspend={3} />}>
          <CardBody columns={columns} contract={contract} />
        </Suspense>
      </Card>
      <AddModal isOpen={isOpen} toggle={handleToggle} contract={contract} />
      <EditModal isOpen={editModal.hasState} toggle={editModal.toggle} id={editModal.value} contract={contract} />
      <DeleteModal delete={deleteModal} contract={contract} />
    </>
  )
}

export default Interviewers
