import React from 'react'
import LinkTemplate, { LinkTemplateProps } from 'src/components/Links/LinkTemplate'

interface ContractLinkProps extends Omit<LinkTemplateProps, 'to'> {}

const ContractLink: React.FC<React.PropsWithChildren<ContractLinkProps>> = (props) => {
  const { _id, children } = props
  return (
    <LinkTemplate _id={_id} to={`/cat/contracts/${String(_id)}`}>
      {children}
    </LinkTemplate>
  )
}

export default ContractLink
export { ContractLink }
