import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createColumnHelper } from '@tanstack/react-table'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { ContractInterviewers } from 'src/queries'

type TData = ContractInterviewers[0]

const columnHelper = createColumnHelper<TData>()

export type Columns = Array<ReturnType<typeof columnHelper.accessor>>

export interface Action {
  id: string
  title: string
  icon: IconProp
  color?: string
  onClick: (id: string) => void
}

const createColumns = (actions: Action[]): Columns => [
  columnHelper.accessor('name', {
    header: 'Name'
  }),
  columnHelper.accessor('title', {
    header: 'Title'
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: 'E-Mail',
    cell: (info) => {
      const value = info.getValue()
      if (typeof value === 'string' && value.length > 0) {
        return (
          <Link to={`mailto:${value}`}>{value}</Link>
        )
      }
      return value
    }
  }),
  columnHelper.accessor('_id', {
    header: ' ',
    enableSorting: false,
    cell: (info) => {
      const value = info.getValue()
      return (
        <div className='text-end'>
          <UncontrolledDropdown>
            <DropdownToggle color='link' className='text-reset'><FontAwesomeIcon icon={faEllipsisVertical} /></DropdownToggle>
            <DropdownMenu>
              {actions.map((action) => (
                <DropdownItem
                  key={action.id}
                  onClick={() => action.onClick(value)}
                  className={classNames({
                    [`text-${action.color ?? ''}`]: action.color
                  })}
                >
                  <FontAwesomeIcon icon={action.icon} /> {action.title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      )
    }
  })
]

export { createColumns }
