import React from 'react'
import Modal, { Header } from 'src/components/theme/Models/Modal'
import FormContext from './FormContext'
import FormSuspense from 'src/components/Forms/FormSuspense'
import Form from './Form'

interface EditModalProps {
  isOpen: boolean
  toggle: () => void
  contract: string
  id?: string
}

const EditModal: React.FC<EditModalProps> = (props) => {
  const { isOpen, toggle, id, contract } = props

  if (id === undefined) {
    return null
  }

  return (
    <Modal
      title='Edit Interviewer'
      isOpen={isOpen}
      toggle={toggle}
    >
      <Header title='Edit Interviewer' />
      <FormSuspense fallback={<Form toggle={toggle} disableSave />}>
        <FormContext toggle={toggle} id={id} contract={contract} />
      </FormSuspense>
    </Modal>
  )
}

export default EditModal
