import React from 'react'
import { useDashboardState } from '../useDashboardState'
import { useAverageTimeToFill } from 'src/queries/cat/reporting/useAverageTimeToFill'

const Body: React.FC = (props) => {
  const { start, end } = useDashboardState()
  const avg = useAverageTimeToFill({ start, end })
  return (
    <h2 className='text-uppercase fw-bold'>{avg.avg} Days</h2>
  )
}

export default Body
