import React from 'react'
import { Col, ColProps, Row } from 'reactstrap'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import { currency } from 'src/helpers/displayhelpers'
import { Link } from 'react-router-dom'
import { PositionDetailsById, PositionRatesById } from 'src/queries'
import { ContractLink } from 'src/components/Links'

interface ListProps {
  details: PositionDetailsById | undefined
  rates: PositionRatesById | undefined
}

const List: React.FC<ListProps> = (props) => {
  const details = props.details ?? undefined
  const rates = props.rates ?? undefined
  const contractType = details?._contract_type?.name ?? undefined
  const section1: Array<Omit<ItemProps, 'id'>> = [
    {
      title: 'Number',
      value: details !== undefined
        ? (
          <Link to={`/cat/positions/${details._id}`}>{details?.id}</Link>
          )
        : null
    },
    {
      title: 'Title',
      value: details !== undefined
        ? (
          <Link to={`/cat/positions/${details._id}`}>{details?.title}</Link>
          )
        : null
    },
    {
      title: 'Contract',
      value: (
        <>
          <ContractLink _id={details?._contract?._id}>
            {details?._contract?.abbreviation}
          </ContractLink>
          {contractType !== undefined ? ` (${contractType})` : null}
        </>
      )
    }
  ]
  const section2 = [
    { title: 'Salary (Min)', value: currency(rates?.salary?.min) },
    { title: 'Salary (Max)', value: currency(rates?.salary?.max) }
  ]
  const section3 = [
    { title: 'Bill Rate (Min)', value: currency(rates?.bill_rate?.min) },
    { title: 'Bill Rate (Max)', value: currency(rates?.bill_rate?.max) }
  ]
  const dlProps = {
    className: 'row-cols-1',
    suspended: details === undefined || rates === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  const colProps: ColProps = {
    md: 4,
    sm: 12
  }
  return (
    <Row>
      <Col {...colProps}>
        <DescriptionList {...dlProps}>
          {section1.map(item => {
            const key = item.title.toLowerCase().replace(' ', '-')
            return <Item {...item} key={key} id={key} />
          })}
        </DescriptionList>
      </Col>
      <Col {...colProps}>
        <DescriptionList {...dlProps}>
          {section2.map(item => {
            const key = item.title.toLowerCase().replace(' ', '-')
            return <Item {...item} key={key} id={key} />
          })}
        </DescriptionList>
      </Col>
      <Col {...colProps}>
        <DescriptionList {...dlProps}>
          {section3.map(item => {
            const key = item.title.toLowerCase().replace(' ', '-')
            return <Item {...item} key={key} id={key} />
          })}
        </DescriptionList>
      </Col>
    </Row>
  )
}

export default List
