import React, { useEffect } from 'react'
import { Table } from '@imcva/reactstrap-table'
import { Columns } from './columns'
import { useCardContext } from 'src/components/Card/CardContext'
import { useContractInterviewers } from 'src/queries'

interface CardBodyProps {
  columns: Columns
  contract: string
}

const Body: React.FC<CardBodyProps> = (props) => {
  const { columns, contract } = props
  const interviewers = useContractInterviewers({
    filter: {
      contract
    }
  })
  const card = useCardContext()
  const count = interviewers.length
  useEffect(() => {
    card.setTitle(`Interviewers (${String(count)})`)
  }, [count])
  return (
    <Table storageKey='contract-interviewers' options={{ data: interviewers, columns, initialState: { sorting: [{ id: 'name', desc: false }] } }} />
  )
}

export default Body
