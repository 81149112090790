import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import React from 'react'
import { DatePicker } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
}

const Form: React.FC<FormProps> = (props) => {
  const { hasError, toggle, isLoading, disableSave } = props
  const { handleSubmit } = useFormikContext()
  return (
    <>
      <Body error={{
        error: 'There was an issue updating the candidate interviews.',
        show: hasError ?? false
      }}
      >
        <DatePicker name='exhibit.sent_for_review' label='Sent for Review' />
        <DatePicker name='exhibit.hm_approved' label='HM Approved' />
        <DatePicker name='exhibit.contracts_approved' label='Contracts Approved' />
        <DatePicker name='exhibit.pmo_approved' label='PSO Approved' />
        <DatePicker name='exhibit.extended' label='Extended' />
        <DatePicker name='exhibit.signed' label='Signed' />
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Save',
          icon: isLoading === true ? faSpinner : faSave,
          color: 'primary',
          disabled: disableSave === true || isLoading
        }}
      />
    </>
  )
}

export default Form
