import { graphql } from 'src/gql'
import { ContractInterviewersQueryVariables, SortFindManyContractInterviewerInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL, UseGraphqlOptions } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query contractInterviewers($sort: SortFindManyContractInterviewerInput, $filter: FilterFindManyContractInterviewerInput) {
    cat {
      contractInterviewer {
        many(sort: $sort, filter: $filter) {
          _id
          name
          title
          email
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type ContractInterviewers = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['contractInterviewer']>['many']>

const useContractInterviewers = (variables?: ContractInterviewersQueryVariables, options?: UseGraphqlOptions<TData>, enabled: boolean = true): ContractInterviewers => {
  const internalVariables: ContractInterviewersQueryVariables = Object.assign({
    sort: SortFindManyContractInterviewerInput.NameAsc,
    limit: 1000
  }, variables)
  const { data } = useGraphQL(document, internalVariables, options)
  return data?.cat?.contractInterviewer?.many ?? []
}

export { useContractInterviewers, document as contractInterviewersDocument }
