import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import React, { useState } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  DropdownItemProps
} from 'reactstrap'
import { RemoveIndex } from 'src/types'

interface ActionPropsStandard extends Omit<RemoveIndex<DropdownItemProps>, 'children' | 'text' > {
  key: string
  icon: IconDefinition
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>
  text?: string
  permissionKey?: string
}

interface ActionPropsCustom extends Omit<RemoveIndex<DropdownItemProps>, 'key'> {
  id: string
}

type ActionProps = ActionPropsStandard | ActionPropsCustom

export interface SecondaryActionsProps {
  actions: ActionProps[]
}

const SecondaryActions: React.FC<SecondaryActionsProps> = (props) => {
  const { actions } = props
  const [open, setOpen] = useState(false)
  const toggle = (): void => setOpen(o => !o)
  return (
    <Dropdown isOpen={open} toggle={toggle} data-testid='card-secondary-actions'>
      <DropdownToggle tag='div' role='button' data-testid='card-secondary-action-toggle'>
        <FontAwesomeIcon icon={faEllipsisVertical} fixedWidth />
      </DropdownToggle>
      {open
        ? (
          <DropdownMenu>
            {actions.map((item) => {
              if ('key' in item) {
                const { key, icon, iconProps, text, permissionKey, ...rest } = item
                return (
                  <DropdownItem data-testid={`secondary-action-${key}`} {...rest} key={item.key}>
                    <FontAwesomeIcon icon={item.icon} {...item.iconProps} /> {item.text}
                  </DropdownItem>
                )
              } else {
                return (
                  <DropdownItem {...item} key={item.id} />
                )
              }
            })}
          </DropdownMenu>
          )
        : null}
    </Dropdown>
  )
}

export default SecondaryActions
