import React, { Suspense } from 'react'
import Card from 'src/components/Card'
import Body from './Body'
import Skeleton from './Skeleton'

const AverageTimeToFill: React.FC = (props) => {
  return (
    <Card title='Avg Time to Fill'>
      <div className='d-flex h-100 w-100 justify-content-center align-items-center'>
        <Suspense fallback={<Skeleton />}>
          <Body />
        </Suspense>
      </div>
    </Card>
  )
}

export default AverageTimeToFill
