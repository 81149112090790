import React from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import RouteError from 'src/helpers/RouteError'

import Details from './cards/Details'
import PositionCounts from './cards/PositionCounts'
import Status from './cards/Status'
import Onboarding from './cards/Onboarding'
import { useContractDetailsById } from 'src/queries'
import Notes from './cards/Notes'
import PositionList from './cards/PositionList'
import Interviewers from './cards/Interviewers'
import PoP from 'src/pages/cat/contracts/Contract/cards/PoP'

const Contract: React.FC = (props) => {
  const params = useParams()
  const id = params.id
  if (id === undefined || id == null) {
    throw new RouteError('Invalid Contract ID.', { status: 404 })
  }
  const contract = useContractDetailsById(id)
  if (contract === null) {
    throw new RouteError(`Contract "${id}" does not exist.`, { status: 404 })
  }
  return (
    <>
      <Row className='gy-4 mb-4'>
        <Col md='6'>
          <Details id={id} />
        </Col>
        <Col md='3'>
          <PositionCounts id={id} />
        </Col>
        <Col md='3' className='d-flex'>
          <Status id={id} />
        </Col>
      </Row>
      <Row className='mb-4 gy-4'>
        <Col md='6'>
          <Row className='gy-4'>
            <Col md='12'>
              <PoP id={id} />
            </Col>
            <Col md='12'>
              <Onboarding id={id} />
            </Col>
          </Row>
        </Col>
        <Col md='6'>
          <Notes id={id} />
        </Col>
      </Row>
      <Row className='gy-4 mb-4'>
        <Col md='12'>
          <Interviewers contract={id} />
        </Col>
      </Row>
      <Row className='gy-4'>
        <Col md='12'>
          <PositionList contract={id} />
        </Col>
      </Row>
    </>
  )
}

export default Contract
